import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import firebase from "firebase/app";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'How to use Comprimido',
    paragraph: 'Install - Connect - Setup'
  };

  const analytics = firebase.analytics();

  function windowsDownload(){
    analytics.logEvent('comprimido_host_windows_download')
  }
  function linuxDownload(){
    analytics.logEvent('comprimido_host_linux_download')
  }
  function macOSDownload(){
    analytics.logEvent('comprimido_host_macos_download')
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Download
                  </div>
                <h3 className="mt-0 mb-12">
                  Install host software to your PC
                  </h3>
                <p className="m-0">
                  Just download host software feat to your PC.<br/>
                  Your host access will be protected by password authentication.
                  </p>

                <ButtonGroup>
                  <Button tag="a" onClick={windowsDownload} color="dark" wideMobile href="https://comprimido-host-software.jgroup.space/comprimido-windows.zip">
                    Windows
                    </Button>
                  <Button tag="a" onClick={linuxDownload} color="dark" wideMobile href="https://comprimido-host-software.jgroup.space/comprimido-linux.zip">
                    Linux
                    </Button>
                  <Button tag="a" onClick={macOSDownload} color="dark" wideMobile href="https://comprimido-host-software.jgroup.space/comprimido-macos.zip">
                    macOS
                    </Button>
                </ButtonGroup>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Scan
                  </div>
                <h3 className="mt-0 mb-12">
                  Scan host PCs in network
                  </h3>
                <p className="m-0">
                  Scan hosts in same network (e.g WIFI). <br/>Also, you can manually add host by IP
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  AUTHENTICATE
                  </div>
                <h3 className="mt-0 mb-12">
                  Authenticate with host's password
                  </h3>
                <p className="m-0">
                  Click host's comprimido tray icon for get password.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Start
                  </div>
                <h3 className="mt-0 mb-12">
                  Now you can use Tablet/Trackpad
                  </h3>
                <p className="m-0">
                  Eeeeeeeeeeeeeeeeeeeeeasy.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-04.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
