import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import firebase from "firebase/app";
import "firebase/analytics";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAL3bFXSHwsNyNjdY9_plx15GYNQO_fp1s",
  authDomain: "comprimido-48e6f.firebaseapp.com",
  projectId: "comprimido-48e6f",
  storageBucket: "comprimido-48e6f.appspot.com",
  messagingSenderId: "48887320606",
  appId: "1:48887320606:web:0987f6d3cb1ffa70ca5e0f",
  measurementId: "G-7T2LV3RM9W"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;